
body.login {
  background: linear-gradient(to bottom, #E3F4fE 0%, #FFF 110%);
  // background-color: blue;
}

#login {
  h1 a {
    background-image: url('https://www.enersol.be/wp/wp-content/themes/enersol/assets//images/chrome/logo-enersol-4d28caebd6.png');
    background-size: 320px;
    width: 320px;
  }
}
