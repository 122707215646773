/* stylelint-disable font-family-no-missing-generic-family-keyword */
%font-awesome-icon {
  font-family: 'Font Awesome 5 Free';
  font-size: 18px;
}

%font-awesome-brands {
  font-family: 'Font Awesome 5 Brands';
  font-size: 18px;
}
